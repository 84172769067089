import { createTheme  } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

const Theme = {
  palette: {
    primary: {
      main: '#4e5ffb',
      light: '#707df4',
      dark:'#141f4d',
    },
    secondary: {
      main: '#ca1ae9',
    }
  },

  typography: {
    fontFamily: '"Roboto", Arial, sans-serif',
    htmlFontSize: 16,
    h1: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '2.5rem',
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: 1,
    },
    h2: {
      fontFamily: '"Roboto", Arial, sans-serif',
      fontSize: '1.5rem',
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: 1,
    },
  },

  components: {
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 'calc(100% - 50px)',
          whiteSpace: 'nowrap',
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        virtualScroller: {
          "::-webkit-scrollbar": {
            width: 8,
            height: 10,
          },
          "::-webkit-scrollbar-thumb": {
            background: '#707df4',
            borderRadius: 5,
            height: '10px',
            border: '2px solid transparent',
          },
          scrollbarWidth: 'thin',
          scrollbarColor: `#707df4 transparent`,
        },
      },
    },
  },
};

export default createTheme(Theme, frFR);
