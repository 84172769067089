import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

import ClearIcon from '@mui/icons-material/Clear';

import AutoSelect from '../../AutoSelect';

import styles from './styles.js';
import moment from 'moment';

class ToolbarFilterContent extends React.Component {
  static defaultProps = {
    params: []
  }

  /**
   * RENDER
   */
  renderTextField(param) {
    const { filters, onChange } = this.props;
    return (
      <TextField
        key={param.key}
        name={param.key}
        value={filters[param.key] || ''}
        label={param.label || param.key}
        type="text"
        autoComplete="off"
        // placeholder={param.label || param.key}
        variant="standard"
        onChange={evt => onChange(evt.target.name, evt.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => onChange(param.key, '')}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    );
  }

  renderPicker(param) {
    const { filters, onChange } = this.props;
    let value = filters[param.key]
    if (value && !moment.isMoment(value)) {
      value = moment(value);
    }
    return (
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
        {param.type === 'date' && (
          <DatePicker
            format={param.format || 'YYYY-MM-DD'}
            label={param.label || param.key}
            value={value || null}
            onChange={evt => onChange(param.key, evt.format('YYYY-MM-DD'))}
            renderInput={(params) => <TextField fullWidth={param.fullWidth} variant={param.variant} {...params} />}
            clearable
          />
        )}
        {param.type === 'datetime' && (
          <DateTimePicker
            format={param.format || 'YYYY-MM-DD HH:mm:ss'}
            label={param.label || param.key}
            value={value || null}
            onChange={evt => onChange(param.key, evt.format('YYYY-MM-DD HH:mm:ss'))}
            renderInput={(params) => <TextField fullWidth={param.fullWidth} variant={param.variant} {...params} />}
            clearable
          />
        )}
        {param.type === 'time' && (
          <TimePicker
            format={param.format || 'HH:mm:ss'}
            label={param.label || param.key}
            value={value || null}
            onChange={evt => onChange(param.key, evt.format('HH:mm:ss'))}
            renderInput={(params) => <TextField fullWidth={param.fullWidth} variant={param.variant} {...params} />}
            clearable
          />
        )}
        <IconButton size="small" onClick={() => onChange(param.key, null)}>
          <ClearIcon />
        </IconButton>
      </Stack>
    );
  }

  renderSelect(param) {
    const { filters, onChange } = this.props;
    const value = param.valueSetter ? param.valueSetter(filters[param.key]) : filters[param.key];
    return (
      <AutoSelect
        {...param}
        key={param.key}
        name={param.key}
        value={value || ''}
        onChange={value => onChange(param.key, param.valueGetter ? param.valueGetter(value) : value || '')}
      />
    );
  }

  renderSwitch(param) {
    const { filters, onChange } = this.props;
    const checked = !!filters[param.key] || filters[param.key] > 0;
    const checkedLabel = param.checked || 'Oui';
    const uncheckedLabel = param.unchecked || 'Non';
    return (
      <FormControl key={param.key} fullWidth>
        {param.label || param.key}
        <FormControlLabel
          control={<Switch checked={checked} onChange={(evt, checkValue) => onChange(param.key, param.valueGetter ? param.valueGetter(checkValue) : checkValue)} />}
          label={checked ? checkedLabel : uncheckedLabel }
        />
      </FormControl>
    );
  }

  renderRange(param) {
    const { filters, onChange } = this.props;
    return (
      <FormControl key={param.label || param.key} fullWidth>
        {param.label || param.key}
        <Stack direction="row" spacing={1}>
          <TextField
            key={param.startkey}
            name={param.startkey}
            value={filters[param.startkey] || ''}
            label="Min"
            type="number"
            autoComplete="off"
            variant="standard"
            onChange={evt => onChange(evt.target.name, evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => onChange(param.startkey, '')}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            key={param.endkey}
            name={param.endkey}
            value={filters[param.endkey] || ''}
            label="Max"
            type="number"
            autoComplete="off"
            variant="standard"
            onChange={evt => onChange(evt.target.name, evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => onChange(param.endkey, '')}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </FormControl>
    );
  }

  render() {
    const { params } = this.props;
    return (
      <Box width={350} sx={{ p: 2 }}>
        <Stack direction="column" spacing={1.5} divider={<Divider variant="inset" flexItem />}>
          {params.map(param => (
            <Box key={param.key || param.label}>
              {(!param.type || param.type === 'text') && this.renderTextField(param)}
              {(param.type === 'date' || param.type === 'datetime' || param.type === 'time') && this.renderPicker(param)}
              {param.type === 'select' && param.options && this.renderSelect(param)}
              {param.type === 'switch' && this.renderSwitch(param)}
              {param.type === 'range' && this.renderRange(param)}
            </Box>
          ))}
        </Stack>
      </Box>
    );
  }
}


ToolbarFilterContent.propTypes = {
  classes: PropTypes.object.isRequired,
  // Main
  filters: PropTypes.object,
  params: PropTypes.array,
  // Func
  onChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(ToolbarFilterContent);
