const styles = {
  wrapper: {
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    paddingRight: 24,
    paddingLeft: 24,
  },
  content: {
    width: 400,
    padding: 24,
  },
  logo: {
    width: 90,
  },
};

export default styles;
