import React from 'react';
import moment from 'moment';
import { Tooltip, IconButton, Stack, Box, Divider, Typography, Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';

import EditableList from '../../components/EditableList';
import DataGrid from '../../components/Grids/DataGrid';
import Notify from '../../components/Notify';
import Ajax from '../../utils/Ajax';
import styles from './styles.js';

class LinksContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRow: null,
      advisoryRow: null,
      advisoryLoading: false,
      advisoryData: [],
    };
  }
  
  async getAdvisory(id) {
    if (!this.state.advisoryRow || id !== this.state.advisoryRow.id) {
      return;
    }
    const result = await Ajax.get({ url:`api/period/${id}`});
    if(result.type === 'error') {
      Notify.error(result.message);
      this.setState({ advisoryData: [], advisoryLoading: false });
      return;
    }
    if (result.id !== this.state.advisoryRow.id) {
      return;
    }
    this.setState({ advisoryData: result.data, advisoryLoading: false });
  }

  renderAdvisory() {
    const { advisoryRow, advisoryData, advisoryLoading } = this.state;
    return (
      <Dialog
        open
        maxWidth="lg"
        disableEscapeKeyDown
        fullWidth
        onClose={() => this.setState({ advisoryRow: null, advisoryLoading: false, advisoryData:[] })}
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between" >
            <Typography variant="h2" color="primary" >Advisory of {advisoryRow.declared_firstname} {advisoryRow.declared_lastname}</Typography>
            <IconButton onClick={() => this.setState({ advisoryRow: null, advisoryLoading: false, advisoryData: [] })}><CloseIcon /></IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent >
          <Box sx={{ height: '70vh' }}>
            <DataGrid
              loading={advisoryLoading}
              data={advisoryData}
              rowKey="period"
              onChangeParams={() => null}
              onPageChange={() => null}
              columns={[
                { field: 'period', headerName: 'Period', width: 130, sortable: false },
                { field: 'min_date', headerName: 'Start date', width: 130, sortable: false },
                { field: 'max_date', headerName: 'End date', width: 130, sortable: false },
                { field: 'transactions_count', headerName: 'Transactions count', width: 180, sortable: false },
                { field: 'incomes', headerName: 'Incomes', width: 180, sortable: false },
                { field: 'expenses', headerName: 'Expenses', width: 180, sortable: false },
                { field: 'disposable', headerName: 'Disposable income', width: 180, sortable: false, valueFormatter: ({ value }) => `${value.value} ${value.currency}` },
              ]}
              dataGridProps={{ hideFooter: true }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  renderModal() {
    const { currentRow } = this.state;
    return (
      <Dialog
        open
        maxWidth="md"
        disableEscapeKeyDown
        fullWidth
        onClose={() => this.setState({ currentRow: null })}
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between" >
            <Typography variant="h2" color="primary" >Details of {currentRow.declared_firstname} {currentRow.declared_lastname}</Typography>
            <IconButton onClick={() => this.setState({ currentRow: null })}><CloseIcon /></IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent >
          <Stack
            direction="row"
            justifyContent="space-around"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Box sx={{ width: '40%' }}>
              <Stack direction="column" spacing={2}>
                <TextField value={currentRow.id_user} label='User id' fullWidth variant='standard' focused  />
                <TextField value={currentRow.state} label='State' fullWidth variant='standard' focused color={currentRow.state !== 'ERROR' && currentRow.state !== 'SUCCESS' ? 'primary' : currentRow.state.toLowerCase() } />
                <TextField value={currentRow.declared_firstname} label='Declared firstname' fullWidth variant='standard' focused />
                <TextField value={currentRow.declared_lastname} label='Declared lastname' fullWidth variant='standard' focused />
                <TextField value={currentRow.declared_folder} label='Internal Ref' fullWidth variant='standard' focused />
                <TextField value={currentRow.date} label='Date' fullWidth variant='standard' focused  />
                <TextField value={currentRow.link} label='Link' fullWidth variant='standard' focused  />
              </Stack>
            </Box>
            <Box sx={{ width: '40%' }}>
              <Stack direction="column" spacing={2}>
                <TextField value={currentRow.real_name} label='Real name' fullWidth variant='standard' focused />
                <TextField value={currentRow.bank_name} label='Bank name' fullWidth variant='standard' focused />
                <TextField value={currentRow.iban} label='IBAN' fullWidth variant='standard' focused />
                <TextField value={currentRow.bic} label='BIC' fullWidth variant='standard' focused />
                <TextField value={currentRow.account_name} label='Account name' fullWidth variant='standard' focused />
                <TextField value={currentRow.account_type} label='Account type' fullWidth variant='standard' focused />
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const { currentRow, advisoryRow } = this.state;
    return (
      <div className={classes.wrapper}>
        {currentRow && this.renderModal()}
        {advisoryRow && this.renderAdvisory()}
        <EditableList
          title="Generated links"
          route="link"
          rowKey="id"
          searchKey="search"
          searchPlaceholder="Names or ref, at least 3 characters"
          searchLabel="Filter"
          defaultParams={{ order: 'id', direction: 'DESC' }}
          columns={[
            {
              field: '__view', headerName: '', width: 120, sortable: false, renderCell: ({ row }) => (
                <>
                  <Tooltip title="See details">
                    <IconButton onClick={() => this.setState({ currentRow:row })}>
                      <RemoveRedEyeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="See advisory">
                    <IconButton onClick={() => this.setState({ advisoryRow: row, advisoryLoading: true }, () => this.getAdvisory(row.id))}>
                      <BarChartIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) 
            },
            { field: 'id', headerName: 'Num', width: 90 },
            { field: 'id_user', headerName: 'User id', width: 90 },
            { field: 'state', headerName: 'State', width: 90 },
            { field: 'declared_firstname', headerName: 'Declared firstname', width: 200 },
            { field: 'declared_lastname', headerName: 'Declared lastname', width: 200 },
            { field: 'declared_folder', headerName: 'Internal Ref', width: 120 },
            { field: 'date', headerName: 'Date', width: 180, valueFormatter: ({ value }) => moment.utc(value, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss") },
            { field: 'link', headerName: 'Link', width: 250, sortable: false },
          ]}
          filterParams={[
            { key: 'id', label: 'ID' },
            { key: 'state', label: 'State', type: 'select', options: ['SENT', 'ERROR', 'SUCCESS' ], variant: 'standard' },
            { key: 'date_min', label: 'Min date', type: 'datetime', valueFormatter: value => moment(value, "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss") },
            { key: 'date_max', label: 'Max date', type: 'datetime', valueFormatter: value => moment(value, "YYYY-MM-DD HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss") },
          ]}
          allowAdd={false}
          allowEdit={false}
          allowDelete
        />
      </div>
    );
  }
}


LinksContainer.propTypes = {}

export default withStyles(styles)(LinksContainer);