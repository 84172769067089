import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ReactComponent as Logo } from '../../images/logo.svg';
import Ajax from '../../utils/Ajax';
import styles from './styles.js';

class WebViewCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
    };
  }

  async componentDidMount() {
    const { search } = window.location;
    const connection = {};
    if(search) {
      search.replaceAll(/(id|connection_id)=(\d+)/g, (txt,key,value) => {
        connection[key] = parseInt(value, 10);
      });
      if (connection.id && connection.connection_id) {
        const result = await Ajax.get({
          url: 'api/link-connection',
          queryString: {
            ...connection
          },
        });
        this.setState({ loading: false, error: result.type === 'error' });
      }
    }
  }

  render() {
    const { classes, } = this.props;
    const { loading, error } = this.state;
    return (
      <Box className={classes.wrapper} >
        <Box sx={{
          boxShadow: { xs: 0, md: 1 },
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}>
          <Stack spacing={2} className={classes.content}>
            <Logo className={classes.logo} />
            <Stack spacing={4} alignItems="center" sx={{paddingTop: 2 }}>
              {loading ? <CircularProgress size={80} /> : null}
              {loading ? <Typography color="primary" >Please wait...</Typography> : null}
              {!loading && !error ? <span style={{fontSize: 110, lineHeight: 0.6, color: '#33AA33'}}><CheckCircleOutlineIcon fontSize="inherit" /></span> : null}
              {!loading && !error ? <span style={{ color: '#33AA33' }}><Typography color="inherit" >Connection success !</Typography></span> : null}
              {!loading && error ? <span style={{ fontSize: 110, lineHeight: 0.6, color: '#AA3333' }}><ErrorOutlineIcon fontSize="inherit" /></span> : null}
              {!loading && error ? <span style={{ color: '#AA3333' }}><Typography color="inherit" >Connection failed !</Typography></span> : null}
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  }
}

WebViewCallback.propTypes = {
  pathname: PropTypes.string,
}

export default withStyles(styles)(WebViewCallback);