import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';

import Config from '../../config';

class Ajax {
  async fetch(url, options) {
    if (isNil(url) || url === '') {
      return { type: "error", message: 'Missing Url', data: {} }
    }
    options = options || {};
    options.credentials = 'include';

    const methods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];
    options.method = (methods.indexOf(options.method) !== -1) ? options.method : 'GET';

    //Body
    if (options.method === 'POST' || options.method === 'PUT' || options.method === 'PATCH') {
      options.headers = new Headers({
        "Content-Type": "application/json",
      });
      options.body = JSON.stringify(options.body || {});
    } else if (!isNil(options.body)) {
      delete options.body;
    }
    if (!isNil(options.queryString)) {
      const queryStringTab = [];
      forEach(options.queryString, (val, key) => {
        if (Array.isArray(val)) {
          forEach(val, subValue => {
            queryStringTab.push(`${encodeURIComponent(key)}=${encodeURIComponent(subValue)}`);
          });
        } else {
          queryStringTab.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
        }
      });
      url = `${url}?${queryStringTab.join('&')}`;
      delete options.queryString;
    }
    //Url
    if (url.substr(0, 3) === 'api') {
      const { HOST, PROTOCOL, PORT } = Config;
      let port = '';
      if (PORT !== '') {
        port = ':' + PORT;
      }
      url = `${PROTOCOL}//${HOST}${port}/${url}`;
    }
    try {
      let res = await fetch(encodeURI(url), options);
      if (options.raw) {
        return res;
      }
      let json = await res.json();
      return json;
    } catch (e) {
      return { type: "error", data: e, message: e.toString() }
    }
  }

  get(params) {
    params = typeof (params) === "string" ? { url: params } : params;
    const { url, ...options } = params;
    options.method = 'GET';
    return this.fetch(url, options);
  }

  post(params) {
    const { url, ...options } = params;
    options.method = 'POST';
    return this.fetch(url, options);
  }

  put(params) {
    const { url, ...options } = params;
    options.method = 'PUT';
    return this.fetch(url, options);
  }

  patch(params) {
    const { url, ...options } = params;
    options.method = 'PATCH';
    return this.fetch(url, options);
  }

  delete(params) {
    params = typeof (params) === "string" ? { url: params } : params;
    const { url, ...options } = params;
    options.method = 'DELETE';
    return this.fetch(url, options);
  }
}

const ajax = new Ajax();

export default ajax;