import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import forEach from 'lodash/forEach';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import InputAdornment from '@mui/material/InputAdornment';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';

import Confirm from '../../Confirm';
import ToolbarFilterContent from './ToolbarFilterContent';

import { DEFAULT_PARAMS } from '../DataGrid/constants';
import Config from '../../../config';

import styles from './styles.js';

class DataGridToolbar extends React.Component {
  static defaultProps = {
    rowCount: 0,
    filters: {},
    filterParams: [],
    selectedRows: [],
    additionnalRowActions: [],
    additionnalActions: [],
    allowAdd: true,
    allowDelete: true,
    searchKey: 'search',
    searchLabel: 'Filtrer',
    searchPlaceholder: 'Filtrer par ...',
    hideRowActions: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      rowActionAnchorEl: null,
      filterContentOpen: false,
      deleteConfirmOpen: false,
      exportDialogType: null,
      exportFileType: Config.EXPORT_FILE_TYPES[0],
      exportFileSeparator: Config.EXPORT_ALLOWED_SEPARATORS[0],
    };

    this.filterContentRef = React.createRef();
    this.getHasFilterValues = this.getHasFilterValues.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleValidateDelete = this.handleValidateDelete.bind(this);
    this.handleValidateExport = this.handleValidateExport.bind(this);
  }
  /**
   * GET
   */
  getHasFilterValues() {
    const { filters } = this.props;
    let output = false;
    forEach(filters, (value, key) => {
      if (!Object.keys(DEFAULT_PARAMS).includes(key) && !!value) {
        output = true;
      }
    });
    return output;
  }

  /**
   * HANDLES
   */
  handleChangeFilter(name, value) {
    const { filters, onChangeFilters } = this.props;
    onChangeFilters({ ...filters, [name]: !!value ? value : undefined });
  }

  handleValidateDelete() {
    const { selectedRows, onAction } = this.props;
    onAction('delete', selectedRows);
    this.setState({ deleteConfirmOpen: false });
  }

  handleValidateExport() {
    const { selectedRows, onAction } = this.props;
    const { exportDialogType, exportFileType, exportFileSeparator } = this.state;
    const exportParams = { type: exportFileType, separator: exportFileSeparator };
    if (exportDialogType === 'rows') {
      exportParams.rows = selectedRows;
    }
    onAction('export', exportParams);
    this.setState({ exportDialogType: null });
  }

  /**
   * RENDER
   */
  renderDeleteConfirmDialog() {
    const { selectedRows } = this.props;
    const { deleteConfirmOpen } = this.state;
    return (
      <Confirm
        open={deleteConfirmOpen}
        title="Suppression"
        message={`Êtes vous sûr de vouloir supprimer ces ${selectedRows.length} élément(s) ?`}
        cancel="Annuler"
        validate="Supprimer"
        onClickAway={() => this.setState({ deleteConfirmOpen: false })}
        onValidate={this.handleValidateDelete}
      />
    );
  }

  renderExportDialog() {
    const { selectedRows } = this.props;
    const { exportDialogType, exportFileType, exportFileSeparator } = this.state;
    return (
      <Dialog
        open={!!exportDialogType}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Exporter
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <Typography gutterBottom>
              Vous êtes sur le point d'exporter {exportDialogType === 'rows' ? `${selectedRows.length} élément(s)` : 'une liste d\'éléments'}.<br />
              Veuillez séléctionner le type de fichier ainsi que le séparateur le cas échéant, puis cliquez sur valider.
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="export_type_select">
                Type de fichier
              </InputLabel>
              <Select
                labelId="export_type_select"
                label="Type de fichier"
                value={exportFileType}
                onChange={({ target }) => this.setState({ exportFileType: target.value })}
              >
                {Config.EXPORT_FILE_TYPES.map(opt => (
                  <MenuItem key={opt} value={opt}>{opt}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {exportFileType === 'CSV' && (
              <FormControl fullWidth>
                <InputLabel id="export_separator_select">
                  Type de séparateur
                </InputLabel>
                <Select
                  labelId="export_separator_select"
                  label="Type de fichier"
                  value={exportFileSeparator}
                  onChange={({ target }) => this.setState({ exportFileSeparator: target.value })}
                >
                  {Config.EXPORT_ALLOWED_SEPARATORS.map(opt => (
                    <MenuItem key={opt} value={opt}>{opt === ' ' ? 'Espace' : opt}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ exportDialogType: null })}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleValidateExport}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderRowsActionMenu() {
    const { selectedRows, allowDelete, additionnalRowActions } = this.props;
    const { rowActionAnchorEl } = this.state;
    return (
      <Menu
        anchorEl={rowActionAnchorEl}
        keepMounted
        open={!!rowActionAnchorEl}
        onClose={() => this.setState({ rowActionAnchorEl: null })}
      >
        {allowDelete && (
          <MenuItem
            key='delete'
            onClick={() => this.setState({ deleteConfirmOpen: true })}
          >
            Supprimer la sélection
          </MenuItem>
        )}
        {additionnalRowActions.map(action => (
          <MenuItem key={action.key || action.label} onClick={() => action.onClick(selectedRows)}>{action.label}</MenuItem>
        ))}
      </Menu>
    );
  }

  renderFilterContent() {
    if (!this.filterContentRef?.current) {
      return null;
    }
    const { filters, filterParams } = this.props;
    const { filterContentOpen } = this.state;
    return (
      <Popover
        open={filterContentOpen}
        anchorEl={this.filterContentRef?.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => this.setState({ filterContentOpen: false })}
        sx={{ maxHeight: 450 }}
      >
        <Collapse in={filterContentOpen}>
          <ToolbarFilterContent
            filters={filters}
            params={filterParams}
            onChange={this.handleChangeFilter}
            />
        </Collapse>
      </Popover>
    )
  }

  renderSearchPart() {
    const {
      rowCount,
      filters,
      selectedRows,
      filterParams,
      searchLabel,
      searchPlaceholder,
      searchKey,
      additionnalFilterActions,
      onClearFilters,
      hideRowActions,
    } = this.props;
    const hasFilterValues = this.getHasFilterValues();
    return (
      <>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          {!hideRowActions && (
            <IconButton
              color="secondary"
              disabled={selectedRows.length === 0}
              onClick={evt => this.setState({ rowActionAnchorEl: evt.target })}
            >
              <MoreVertIcon size="large" />
            </IconButton>
          )}
          <Box width={350} ref={this.filterContentRef}>
            <TextField
              name={searchKey}
              value={filters[searchKey] || ''}
              label={searchLabel}
              type="text"
              placeholder={searchPlaceholder}
              variant="standard"
              color="secondary"
              onChange={evt => this.handleChangeFilter(evt.target.name, evt.target.value)}
              autoComplete='off'
              InputProps={{
                endAdornment: filterParams.length > 0 && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => this.setState({ filterContentOpen: true })}>
                      <Badge variant="dot" overlap="circular" color="secondary" invisible={!hasFilterValues}>
                        <ExpandMoreIcon />
                      </Badge>
                    </IconButton>
                    {hasFilterValues && (
                      <IconButton size="small" onClick={() => onClearFilters()}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          <Typography color="secondary">{rowCount.toLocaleString('fr-FR')} résultat{rowCount !== 1 && 's'}</Typography>
        </Stack>
        {additionnalFilterActions.map(action => action)}
        {this.renderRowsActionMenu()}
        {this.renderFilterContent()}
      </>
    )
  }

  renderActionsPart() {
    const { additionnalActions, allowAdd, onAction } = this.props;
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        {additionnalActions.map(action => action)}
        {allowAdd && (
          <Tooltip title="Ajouter" arrow>
            <span>
              <Fab color="secondary" onClick={() => onAction('add')}>
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>
        )}
      </Stack>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {this.renderSearchPart()}
          {this.renderActionsPart()}
          {this.renderDeleteConfirmDialog()}
          {this.renderExportDialog()}
        </Stack>
      </div>
    );
  }
}

DataGridToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  // Main
  rowCount: PropTypes.number,
  filters: PropTypes.object,
  filterParams: PropTypes.array,
  selectedRows: PropTypes.array,
  // More
  additionnalRowActions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  })),
  additionnalActions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.func]),
  additionnalFilterActions: PropTypes.arrayOf(PropTypes.node),
  // Restrictions
  allowAdd: PropTypes.bool,
  allowDelete: PropTypes.bool,
  // Funcs
  onAction: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
  // Search
  searchKey: PropTypes.string,
  searchLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  // Others
  hideRowActions: PropTypes.bool,
}

export default withStyles(styles)(DataGridToolbar);
