import Theme from '../../theme';

const styles = {
  appBar: {
    backgroundColor: `${Theme.palette.background.default} !important`,
    height: Theme.spacing(10),
  },
  logo: {
    width: 133,
  },
  appBarContainer: {
    padding: Theme.spacing(2), 
    maxWidth: 1200, 
    width: '100%'
  },
  mainContainer: {
    paddingTop: Theme.spacing(16),
    paddingLeft: Theme.spacing(2),
    paddingRight: Theme.spacing(2),
    paddingBottom: Theme.spacing(4),
    maxWidth: 1200,
    width: '100%',
  },
  notifyContainer: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    width: 'auto',
    zIndex: 10000,
  },
};

export default styles;