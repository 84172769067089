import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Button, Skeleton } from '@mui/material';
import { withStyles } from '@mui/styles';

import { ReactComponent as Logo } from '../../images/logo.svg';
import Ajax from '../../utils/Ajax';
import styles from './styles.js';

class WebView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      item: {},
    };
  }

  async componentDidMount() {
    const id = parseInt(this.props.pathname.split('/link/')[1], 10);
    const result = await Ajax.get({ url: `api/link/${id}` });
    if (result.error) {
      this.setState({ loading: false, error: true });
    } else {
      this.setState({ loading: false, error: false, item: result.item });
    }
  }

  render() {
    const { classes, } = this.props;
    const { loading, error, item } = this.state;
    return (
      <Box className={classes.wrapper} >
        <Box sx={{ 
          boxShadow: {xs: 0, md: 1},
          bgcolor: 'background.paper',
          borderRadius: 1,
          }}>
          <Stack spacing={2} className={classes.content}>
            <Logo className={classes.logo} />
            {
              loading 
                ? <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
                : error 
                  ? <Typography variant="h2" color="error">Error, invalid Link</Typography>
                  : <Typography variant="h2" color="primary">Welcome {item.declared_firstname} {item.declared_lastname}</Typography>
            }
            {!loading && !error && item.state === 'SENT' && <Typography>A connection is required</Typography>}
            {!loading && !error && item.state !== 'SENT' && <Typography>This link was exipred !</Typography>}
            <Stack sx={{ paddingTop: 4 }}>
              <Button disabled={loading || item.state !== 'SENT'} href={item.api_link} variant='outlined'>Connection</Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  }
}

WebView.propTypes = {
  pathname: PropTypes.string,
}

export default withStyles(styles)(WebView);