import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarContent from '@mui/material/SnackbarContent';
import Tooltip from '@mui/material/Tooltip';

import CopyIcon from '@mui/icons-material/CopyAll';
import { withStyles } from '@mui/styles';

import Notify from '../../components/Notify';
import Ajax from '../../utils/Ajax';
import styles from './styles.js';

class GenerateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastname: '',
      firstname: '',
      internal: '',
      error: false,
      loading: false,
      link: '',
    }

    this.onGenerate = this.onGenerate.bind(this);
    this.onCopy = this.onCopy.bind(this);
  }

  async onGenerate() {
    const { lastname, firstname, internal } = this.state;
    if(!lastname.trim()) {
      this.setState({ error: true });
      return;
    }
    await new Promise(resolve => this.setState({ loading: true }, resolve));

    const result = await Ajax.post({
      url: 'api/link',
      body: {
        declared_lastname: lastname.trim(),
        declared_firstname: firstname.trim(),
        declared_folder: internal.trim(),
      }
    });
    if(result.type === 'error') {
      console.log(result);
      this.setState({ loading: false });
      Notify.error(result.message);
    } else {
      const { link } = result.item;
      this.setState({ lastname: '', firstname: '', internal: '', link, loading: false });
    }
  }

  onCopy() {
    navigator.clipboard.writeText(this.state.link);
    Notify.info('Link copied to clipboard');
  }

  render() {
    const { classes } = this.props;
    const { lastname, firstname, internal, loading, error, link } = this.state;
    return (
      <Box>
        <Typography variant='h1' color="primary">Generate a New Link</Typography>
        <Stack spacing={2} direction='row' className={classes.mainContainer}>
          <TextField label='Firstname' value={firstname} variant='outlined' focused fullWidth onChange={evt => this.setState({ firstname: evt.target.value })} />
          <TextField label='Lastname' error={error} helperText={error ? 'This field is required' : null}  value={lastname} required variant='outlined' focused fullWidth onChange={evt => this.setState({ lastname: evt.target.value, error: false })}/>
          <TextField label='Internal Ref' value={internal} variant='outlined' focused fullWidth onChange={evt => this.setState({ internal: evt.target.value })} />
        </Stack>
        <Stack spacing={2} direction='row' justifyContent="flex-end">
          <Button disabled={loading} onClick={this.onGenerate} >
            Generate
            {loading ? <CircularProgress size={20} color='inherit' sx={{ marginLeft: 2 }}/> : null}
          </Button>
        </Stack>
        {
          link ? (
            <>
              <Typography variant='h1' color="secondary">Generated Link</Typography>
              <Stack spacing={2} direction='column' className={classes.mainContainer}>
                <SnackbarContent message={link} className={classes.snackBar} action={(
                  <Tooltip title="Copy to clipboard">
                    <IconButton color="secondary" onClick={this.onCopy}>
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>)} 
                />
              </Stack>
              <Stack spacing={2} direction='row' justifyContent="flex-end">
                <Button disabled={loading} color="secondary" onClick={() => this.setState({ link: '' })} >
                  Clear
                </Button>
              </Stack>
            </>
          ) : null
        }
      </Box>
    );
  }
}


GenerateContainer.propTypes = {}

export default withStyles(styles)(GenerateContainer);