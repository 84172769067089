// import Theme from '../../theme';

const styles = {
  wrapper: {
    height: 'calc(100vh - 160px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
  },
};

export default styles;


