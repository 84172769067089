import React from 'react';
import { Box, Stack, Typography, Button  } from '@mui/material';
import { withStyles } from '@mui/styles';

import { ReactComponent as Logo } from '../../images/logo.svg';
import styles from './styles.js';

class WelcomeContainer extends React.Component {
  render () {
    const { classes, } = this.props;
    return (
      <Box className={classes.wrapper} >
        <Box sx={{
          boxShadow: { xs: 0, md: 1 },
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}>
          <Stack spacing={2} className={classes.content}>
            <Typography variant="h2" color="primary">Demontrator</Typography>
            <Logo className={classes.logo} />
            <Stack sx={{ paddingTop: 4 }}>
              <Button onClick={() => this.props.redirect('/admin')} variant='outlined'>Connection</Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  }
}

WelcomeContainer.propTypes = {}

export default withStyles(styles)(WelcomeContainer);