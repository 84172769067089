import React from 'react';
import PropTypes from 'prop-types';
import { matchPath, withRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { withStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';

import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Theme from '../../theme';
import { ReactComponent as Logo } from '../../images/logo.svg';
import WelcomeContainer from '../WelcomeContainer';
import GenerateContainer from '../GenerateContainer';
import LinksContainer from '../LinksContainer';
import WebView from '../WebView';
import WebViewCallback from '../WebViewCallback';
import NotFound from '../NotFound';
import Notify from '../../components/Notify';

import styles from './styles.js';

const paths = [
  {
    route: '/notFound',
    container: NotFound,
  },
  {
    route: '/',
    container: WelcomeContainer,
  },
  {
    route: '/admin',
    container: GenerateContainer,
    admin: true,
    tab: 0,
  },
  {
    route: '/admin/generate',
    container: GenerateContainer,
    admin: true,
    tab: 0,
  },
  {
    route: '/admin/list',
    container: LinksContainer,
    admin: true,
    tab: 1,
  },
  {
    route: '/link/:id(\\d+)',
    container: WebView,
  },
  {
    route: '/callback',
    container: WebViewCallback,
  }
];


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
    }
    this.onTabChange = this.onTabChange.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  redirect(pathname) {
    if (pathname !== this.pathname) {
      this.props.history.push(pathname);
    }
  }

  onTabChange(evt, tab) {
    if(tab === 1) {
      this.redirect('/admin/list');
    } else {
      this.redirect('/admin/generate');
    }
  }

  getPath() {
    const { pathname } = this.props.location;
    let match;
    for(let i = 0; i < paths.length; i++) {
      match = matchPath(pathname, {
        path: paths[i].route,
        strict: false,
        exact: true
      });
      if (match) {
        return paths[i];
      }
    }
    return paths[0];
  }

  renderAdmin(Container, tab) {
    const { classes } = this.props;
    return (
      <>
        <AppBar className={classes.appBar}>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} className={classes.appBarContainer} >
              <Logo className={classes.logo} />
              <Tabs value={tab} onChange={this.onTabChange} indicatorColor='secondary' textColor='secondary'>
                <Tab label="Generate link" />
                <Tab label="Show links" />
              </Tabs>
            </Stack>
          </Stack>
        </AppBar>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Box className={classes.mainContainer}>
            <Container redirect={this.redirect}/>
          </Box>
        </Stack>
        <Stack spacing={1} className={classes.notifyContainer}>
          <Notify />
        </Stack>
      </>
    );
  }

  renderGeneric(Container) {
    const { pathname } = this.props.location;
    return <Container redirect={this.redirect} pathname={pathname} />;
  }

  render() {
    const { admin, container, tab } = this.getPath();
    return (
      <ThemeProvider theme={Theme} >
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
          { admin ? this.renderAdmin(container, tab) : this.renderGeneric(container) }
        </LocalizationProvider>
      </ThemeProvider >
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(withStyles(styles)(App));