import React from 'react';
import PropTypes from 'prop-types';

import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
// import localeText from './utils/localeText';

import DataGridLoadingOverlay from './components/loadingOverlay';
import DataGridCell from './components/cell';

import { DEFAULT_PARAMS } from './constants';
import DragScroll from './utils/dragscroll';

/**
 * DataGrid wrapper
 */
class DataGrid extends React.PureComponent {
  static defaultProps = {
    data: [],
    columns: [],
    count: 0,
    params: { ...DEFAULT_PARAMS },
    selectedRows: [],
    rowsPerPageOptions: [10, 25, 50, 100],
    dataGridProps: {},
  }

  constructor(props) {
    super(props);

    this.handleSetSortMode = this.handleSetSortMode.bind(this);
    this.handleSetPageSize = this.handleSetPageSize.bind(this);

    this.datagridRef = React.createRef();
  }
  
  componentDidUpdate() {
    if (this.datagridRef && this.datagridRef.current) {
      const gridEl = this.datagridRef.current.querySelector('.MuiDataGrid-virtualScroller');
      if (!this.dragscroll) {
        this.dragscroll = new DragScroll(gridEl);
      } else {
        this.dragscroll.initEl(gridEl);
      }
    }
  }

  componentWillUnmount() {
    if (this.dragscroll) {
      this.dragscroll.willUnmount();
    }
  }

  handleSetSortMode(mode) {
    let order = this.props.rowKey;
    let direction = 'ASC';
    if (mode.length > 0) {
      order = mode[0].field;
      direction = mode[0].sort.toUpperCase();
    }
    const { params, onChangeParams } = this.props;
    onChangeParams({...params, order, direction });
  }

  handleSetPageSize(limit) {
    const { params, onChangeParams } = this.props;
    onChangeParams({ ...params, limit });
  }

  render() {
    const {
      data,
      rowKey,
      columns,
      count,
      params,
      rowsPerPageOptions,
      selectedRows,
      loading,
      onPageChange,
      onChangeSelectedRows,
      dataGridProps
    } = this.props;
    const { offset, limit, order, direction } = params;
    const pageSize = Math.floor(offset / limit);
    return (
      <span className="MuiDataGrid-dragscroll" ref={this.datagridRef}>
        <MuiDataGrid
          rows={data}
          rowCount={count}
          columns={columns}
          page={pageSize}
          pageSize={limit}
          scrollbarSize={8}
          rowsPerPageOptions={rowsPerPageOptions}
          sortModel={[{ field: order, sort: direction.toLowerCase() }]}
          checkboxSelection={!!onChangeSelectedRows}
          selectionModel={selectedRows}
          loading={loading}
          paginationMode="server"
          sortingMode="server"
          // localeText={localeText}
          components={{
            LoadingOverlay: DataGridLoadingOverlay,
            Cell: DataGridCell,
          }}
          disableSelectionOnClick
          disableDensitySelector
          disableExtendRowFullWidth
          disableColumnMenu
          disableColumnFilter
          disableVirtualization
          onPageChange={onPageChange}
          onSortModelChange={this.handleSetSortMode}
          onPageSizeChange={this.handleSetPageSize}
          onSelectionModelChange={onChangeSelectedRows}
          getRowId={row =>( row[rowKey])}
          {...dataGridProps}
        />
      </span>
    );
  }
}

DataGrid.propTypes = {
  // Main
  data: PropTypes.array,
  rowKey: PropTypes.string.isRequired,
  columns: PropTypes.array,
  count: PropTypes.number,
  loading: PropTypes.bool,
  // GetList parameters
  params: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
    order: PropTypes.string,
    direction: PropTypes.oneOf(['ASC', 'DESC']),
  }).isRequired,
  // Selection
  selectedRows: PropTypes.array,
  // Options
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  // Functions
  onPageChange: PropTypes.func.isRequired,
  onChangeParams: PropTypes.func.isRequired,
  onChangeSelectedRows: PropTypes.func,
  // Others
  dataGridProps: PropTypes.object,
}

export default DataGrid;