import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ReactComponent as Logo } from '../../images/logo.svg';
import styles from './styles.js';

class NotFound extends React.Component {


  render() {
    const { classes, } = this.props;
    return (
      <Box className={classes.wrapper} >
        <Paper elevation={1}>
          <Stack spacing={2} className={classes.content}>
            <Logo className={classes.logo} />
            <Stack spacing={4} alignItems="center" sx={{paddingTop: 2 }}>
              <span style={{ fontSize: 110, lineHeight: 0.6, color: '#AA3333' }}><ErrorOutlineIcon fontSize="inherit" /></span>
              <span style={{ color: '#AA3333' }}><Typography color="inherit" >Resource not found !</Typography></span>
            </Stack>
          </Stack>
        </Paper>
      </Box>
    );
  }
}

NotFound.propTypes = {
  pathname: PropTypes.string,
}

export default withStyles(styles)(NotFound);