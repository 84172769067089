const ENV = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';

const PROTOCOL = ENV === 'dev' ? 'http:' : 'https:';
const HOST = ENV === 'dev' ? 'localhost' : 'demo-openbanking.com';
const PORT = ENV === 'dev' ? '7601' : '';

const EXPORT_FILE_TYPES = ['CSV', 'JSON'];
const EXPORT_ALLOWED_SEPARATORS = [',', ';', ' '];

const CONFIG = {
  ENV,
  PROTOCOL,
  HOST,
  PORT,
  EXPORT_FILE_TYPES,
  EXPORT_ALLOWED_SEPARATORS
};

export default CONFIG;
